// eslint-disable-next-line no-unused-vars
import React from 'react'
import reactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

import NavBar from './apps/nav-bar'
import AuthModal from './apps/auth-modal'
import EventManager from './apps/event-manager'
import EventDetails from './apps/event-details'

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

const div = document.createElement('div')
div.setAttribute('id', 'FLSH')
document.body.appendChild(div)

const Render = () => (
  <React.Fragment>
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
    <NavBar />
    <AuthModal>
      <Router>
        <Switch>
          <Route path="/events/">
            <EventDetails />
          </Route>
          <Route path="/">
            <EventManager />
          </Route>
          <Route path="/events">
            <EventManager />
          </Route>
        </Switch>
      </Router>
    </AuthModal>
  </React.Fragment>
)

reactDOM.render(
  <Render></Render>,
  document.getElementById('FLSH')
)
