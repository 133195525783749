import { observable } from 'mobx'
import api from '../../api'
import { getters as authState } from '../auth-modal/state'

const state = observable({
  loading: false,
  errored: false,
  events: []
})

const mutations = {
  async readEvents () {
    try {
      state.loading = true
      const res = await api('photographers', 'readEvents', authState.token, { email: authState.email })
      state.events = res
    } catch (e) {
      console.log(e)
      state.errored = true
    } finally {
      state.loading = false
    }
  }
}

export { state, mutations }
