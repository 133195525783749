import { compose, map, fromPairs, filter, path } from 'ramda'

export default compose(
  fromPairs,
  filter(e => e[0]),
  map(e => [e.name, e.value]),
  Array.from,
  path(['target', 'elements']),
  e => { e.preventDefault(); return e }
)
