import React from 'react'

export default props => (
  <div className="col-lg-3">
    <div className="card mt-2">
      <div className="card-body">
        <h5 className="card-title">{props.title}</h5>
        {props.children}
      </div>
    </div>
  </div>
)
