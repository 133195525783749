import { observable } from 'mobx'
import api from '../../api'
import { getters as authState } from '../auth-modal/state'
import pMap from 'p-map'
import uploadQueue from './upload-queue'
import dupeManager from './dupe-manager'

const baseURL = API_BASE_URL
const DUPE_CHECK_PARALLELISM = 5
const UPLOAD_PARALLELISM = 2

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
const RETRY_DELAY_BASE = 1000
const MAX_RETRIES = 3

const blankState = () => ({
  analytics: {},
  errored: false,
  photos: [],
  tagCounts: {},
  uploader: {
    uploading: false,
    progress: 0
  }
})

const state = observable(blankState())
const resetForm = () => document.getElementById('file-uploader-form').reset()

const uploadFile = async (formData, code, token, retries, callback) => {
  const networkCall = async () => {
    const r = await fetch(`${baseURL}/api/upload-photo?code=${code}`, {
      headers: {
        'x-token': token
      },
      method: 'POST',
      body: formData
    })

    return r.json()
  }

  for (let i = 0; i < retries; i++) {
    try {
      const res = await networkCall()
      callback(formData.get('file'), res)
      return
    } catch (e) {
      console.log(e)
      await sleep(RETRY_DELAY_BASE * Math.pow(2, i))
    }
  }

  console.log('upload failed after too many tries')
}

const mutations = {
  async readEvent () {
    Object.assign(state, blankState())
    const code = window.location.pathname.split('/')[2]
    try {
      const res = await api('events', 'read', authState.token, { code })
      Object.assign(state, res)
    } catch (e) {
      state.errored = true
    }

    mutations.readPhotos()
    mutations.readAnalytics()
  },

  async readPhotos () {
    try {
      const res = await api('photographers', 'readPhotos', authState.token, { code: state.code, email: authState.email }) // this is daft, why do I need the email?
      state.photos = res
    } catch (e) {
      state.errored = true
    }
  },

  async addPhoto (photoRes) {
    state.photos.push(photoRes)
  },

  async readAnalytics () {
    try {
      const res = await api('events', 'readAnalytics', authState.token, { code: state.code })
      state.analytics = res
    } catch (e) {
      console.log(e)
      state.errored = true
    }
  },

  async readTagCount (id) {
    try {
      const res = await api('photos', 'readTagCount', authState.token, { id, code: state.code })
      state.tagCounts[id] = res.count
    } catch (e) {
      state.errored = true
    }
  },

  async deletePhoto (id, location) {
    try {
      await api('photos', 'delete', authState.token, { location, code: state.code })
      state.tagCounts[id] = null
      const photo = state.photos.find(photo => photo.id === id)
      console.log('here with photo', photo, photo.original_file_name)
      dupeManager(state.code).remove(photo.original_file_name)
      state.photos = state.photos.filter(photo => photo.id !== id)
    } catch (e) {
      state.errored = true
    }
  },

  async uploadPhotos (e) {
    window.addEventListener('beforeunload', function (e) {
      if (state.uploader.uploadingState === 'uploading') {
        if (!confirm('Upload in progress, are you sure you want to leave?')) {
          e.preventDefault()
          e.returnValue = ''
        }
      }
    })

    window.addEventListener('unload', function (e) {
      alert('running')
      if (state.uploader.uploadingState === 'uploading') {
        state.uploader.uploadingState = false
        state.queue.purge()
      }
    })

    state.uploader.uploadingState = 'uploading'

    state.uploader.queue = uploadQueue()

    const queue = state.uploader.queue
    const target = e.target

    const files = target.files

    try {
      const dupeList = dupeManager(state.code)

      const filesArray = Array.from(files)

      const newFiles = (await pMap(filesArray, async file => {
        if (dupeList.has(file.name)) {
          file.isDupe = true
        } else {
          const res = await api('photos', 'checkIsNameDuplicate', authState.token, { code: state.code, email: authState.email, original_file_name: file.name })
          file.isDupe = res
        }
        return file
      }, { concurrency: DUPE_CHECK_PARALLELISM }))
        .filter(file => !file.isDupe)

      if (newFiles.length === 0) {
        state.uploader.uploadingState = false
        alert('All files have already been uploaded to this event and will be skipped.  We use the file name to detect which are duplicates; if you need to upload a file with the same name, please rename it first.')
        return
      }

      if (newFiles.length !== filesArray.length) {
        alert('Some files have already been uploaded to this event and will be skipped.  We use the file name to detect which are duplicates; if you need to upload a file with the same name, please rename it first.')
      }

      const formsToUpload = newFiles
        .map(file => {
          const formData = new FormData()
          formData.append('file', file)
          return formData
        })

      queue.addItems(...formsToUpload)

      state.uploader.progressNumerator = (formsToUpload.length - queue.length())
      state.uploader.progressDenominator = formsToUpload.length

      const uploadComplete = (file, res) => {
        state.uploader.progressNumerator = (formsToUpload.length - queue.length())
        dupeList.add(file.name)
        mutations.addPhoto(res.body)
      }

      await pMap(formsToUpload, async () => {
        const formData = queue.next()
        if (!formData) return
        await uploadFile(formData, state.code, authState.token, MAX_RETRIES, uploadComplete)
      }, { concurrency: UPLOAD_PARALLELISM })
    } catch (e) {
      console.log('here with an error', e)
    } finally {
      state.uploader.uploadingState = false
      resetForm()
    }
  },

  async purgeQueue (e) {
    state.uploader.uploadingState = 'cancelled'
    state.uploader.queue.purge()

    await sleep(5000)
    state.uploader.uploadingState = false
    resetForm()
  }
}

export { state, mutations }
