import React from 'react'
import { compose } from 'ramda'
import observify from '../../utils/observify'
import submitEventToJSON from '../../utils/submit-event-to-json'
import Card from '../../components/card'

import {
  Link
} from 'react-router-dom'

import { state, mutations } from './state'

const formSubmit = compose(
  mutations.logIn,
  submitEventToJSON
)

const render = (state, props) => {
  if (state.loading) return <div>loading</div>
  if (!state.events.length) return <Card>No events found...</Card>
  return (<div className="row">
    {state.events.slice().reverse().map(event => (
      <Card key={event.id} title={event.display_name}>
        <Link to={`/events/${event.code}`}>
          <button className="btn btn-dark btn-sm">Go to Event</button>
        </Link>
      </Card>)
    )}
  </div>)
}

export default observify((state, props) => {
  React.useEffect(() => {
    mutations.readEvents()
  }, [])

  return render(state, props)
}, state)

// TODO new event support
