import post from './utils/post'

const baseURL = API_BASE_URL;

const fetchApiRes = async (object, method, token, body) => {
  const url = `${baseURL}/api/${object}/${method}`

  return post(url, {
    ...body,
    token
  })
}

export default fetchApiRes
