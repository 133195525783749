import { observable } from 'mobx'
import api from '../../api'

const EMAIL_LOC = 'flsh-email'
const TOKEN_LOC = 'flsh-token'

const state = observable({
  authenticated: false,
  errored: false,
  userData: {}
})

const lsTokenStore = {
  read () {
    return {
      email: localStorage.getItem(EMAIL_LOC),
      token: localStorage.getItem(TOKEN_LOC)
    }
  },
  write (email, token) {
    localStorage.setItem(EMAIL_LOC, email)
    localStorage.setItem(TOKEN_LOC, token)
  }
}

const setAuth = (email, token, authenticated = true) => {
  state.userData.email = email
  state.userData.token = token
  lsTokenStore.write(email, token)
  state.authenticated = authenticated
}

const mutations = {
  async init () {
    const cachedAuth = lsTokenStore.read()
    if (!cachedAuth.token) return
    setAuth(cachedAuth.email, cachedAuth.token)
    if (state.inflight) return
    state.inflight = true
    try {
      await api('check-token', '', cachedAuth.token, {})
    } catch (e) {
      state.authenticated = false
    }
  },
  async logIn (data) {
    try {
      const res = await api('get-token', '', null, data)
      setAuth(data.email, res.token)
    } catch (e) {
      state.errored = true
    }
  },
  logOut () {
    window.location = '/'
    setAuth('', '', false)
  }
}

const getters = {
  get token () {
    return state.userData.token
  },
  get email () {
    return state.userData.email
  },
  get authenticated () {
    return state.authenticated
  }
}

export { state, mutations, getters }
