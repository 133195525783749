const uploadQueue = () => {
  let queue = []

  return {
    next () {
      const next = queue.shift()
      return next
    },
    addItems (...items) {
      queue = [...queue, ...items]
    },
    length () {
      return queue.length
    },
    purge () {
      queue = []
    }
  }
}

export default uploadQueue
