const post = async (url, body) => {
  const res = await fetch(url, {
    headers: {
      'content-type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(body)
  })

  if (res.status < 300) {
    return res.json()
  } else {
    throw new Error(`FetchError, ${res.status}, ${await res.text()}`)
  }
}

export default post
