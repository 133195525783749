import React from 'react'
import observify from '../../utils/observify'
import NavBar from '../../components/nav-bar'

import { getters, mutations } from '../auth-modal/state'

const render = (state, props) => (
  <NavBar authenticated = {getters.authenticated} email={getters.email} logOut={mutations.logOut} />
)

export default observify((state, props) => {
  return render(state, props)
}, getters)
