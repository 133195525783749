export default (code) => {
  const localstorageKey = code + '|dupe-list'
  const dupeList = JSON.parse(localStorage.getItem(localstorageKey) || '{}')

  return {
    add (name) {
      dupeList[name] = true
      localStorage.setItem(localstorageKey, JSON.stringify(dupeList))
    },
    has (name) {
      return dupeList[name]
    },
    remove (name) {
      delete dupeList[name]
      localStorage.setItem(localstorageKey, JSON.stringify(dupeList))
    },
    clear () {
      localStorage.removeItem(localstorageKey)
    }
  }
}
