import React from 'react'
import observify from '../../utils/observify'
import LazyLoad from 'react-lazy-load'

import { state, mutations } from './state'

const render = (state, props) =>
  state.authenticated ? props.children : (
    <div className="row">
      <div className="col-sm-12 col-md-8">
        <h1>Event {state.display_name}</h1>
        <h5>Code named "{state.code}"</h5>
      </div>
      <div className="col-sm-12 col-md-4">
        Analytics:
        <ul className="list-group list-group-flush">
          <li className="list-group-item">Total tagged faces: {state.analytics.tags}</li>
          <li className="list-group-item">Photos with a registered user: {state.analytics.tagged_photos}</li>
          <li className="list-group-item">Users tagged in a photo: {state.analytics.tagged_users}</li>
        </ul>
      </div>
      <div className="col-sm-12 col-md-8">
        <h4>Upload Photos</h4>
        <form onSubmit={e => e.preventDefault()} id="file-uploader-form">
          <input type="file" name="filename" multiple onChange={mutations.uploadPhotos} disabled={state.uploader.uploading} />
        </form>
        {state.uploader.uploadingState === 'uploading' ? <div>
          <span>Completed {state.uploader.progressNumerator || 0} of {state.uploader.progressDenominator || 0} new files.</span>
          <progress value={(state.uploader.progressNumerator / state.uploader.progressDenominator) || 0} className="col-sm-12" />
          <button className="btn btn-dark"
            onClick={() => mutations.purgeQueue()}
          >cancel</button>
        </div> : ''}
        {state.uploader.uploadingState === 'cancelled' ? <div>
          Cancelling...
        </div> : ''}
      </div>
      <div className="col-sm-12">
        <h3>Photos from the event</h3>
        <div className="row">
          {
            state.photos.slice().reverse().map(photo => (
              <div className="col-lg-4 col-sm-12 mb-3" key={photo.id}>
                <LazyLoad
                  height='100%'
                  onContentVisible={() => mutations.readTagCount(photo.id)}
                  key={photo.id}
                  debounce={false}
                >
                  <div className="card">
                    <img src={photo.thumbnail_location || photo.location} className="card-img-top"/>
                    <div className="card-body">
                      <p>User count: {state.tagCounts[photo.id]}</p>
                      <button className="btn btn-dark"
                        onClick={() => mutations.deletePhoto(photo.id, photo.location)} // sort of daft this is by location not by ID
                      >delete</button>
                    </div>
                  </div>
                </LazyLoad>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )

export default observify((state, props) => {
  React.useEffect(() => {
    mutations.readEvent()
  }, [])
  return render(state, props)
}, state)

// TODO bulk upload of photos support
