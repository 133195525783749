import React from 'react'

class NavBar extends React.Component {
  constructor (props) {
    super(props)
    this.sidenav = React.createRef()
  }

  componentDidMount () {
  }

  render () {
    const props = this.props
    return (
      <nav className="navbar navbar-dark bg-dark">
        <div className="container">
          <a className="navbar-brand" href="/">FLSH</a>
          {
            props.authenticated ? (
              <button className="btn btn-secondary" onClick={props.logOut}>Log out</button>
            ) : ''
          }
        </div>
      </nav>
    )
  }
}

export default NavBar
